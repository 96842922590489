import React from 'react';
import SgLayout from '~layouts/sg-layout';
import favicon16 from '~/assets/favicon/favicon-16x16.png';
import favicon32 from '~/assets/favicon/favicon-32x32.png';
import faviconAppleTouch from '~/assets/favicon/apple-touch-icon.png';
import favicon192 from '~/assets/favicon/android-chrome-192x192.png';
import favicon512 from '~/assets/favicon/android-chrome-512x512.png';

const SgFavicon = () => {
    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Favicon</h1>
                    <div className="sg-eg-favicon">
                        <img src={favicon16} />
                        <img src={favicon32} />
                        <img src={faviconAppleTouch} />
                        <img src={favicon192} />
                        <img src={favicon512} />
                    </div>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgFavicon;
